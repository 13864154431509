<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label
              for="filter_search"
            >
              <strong>
                Search
              </strong>
            </label>
            <b-input
              v-model="tableUserAccounts.filter"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="primary"
            @click="onShowAddFormUserAccount"
          >
            Add User
          </b-button>
        </b-col>

      </b-row>

      <b-table
        ref="tableUserAcounts"
        small
        hover
        responsive
        show-empty
        class="mt-2"
        :per-page="tableUserAccounts.perPage"
        :current-page="tableUserAccounts.currentPage"
        :items="myProvider"
        :fields="tableUserAccounts.fields"
        :sort-by.sync="tableUserAccounts.sortBy"
        :sort-desc.sync="tableUserAccounts.sortDesc"
        :sort-direction="tableUserAccounts.sortDirection"
        :filter="tableUserAccounts.filter"
        :filter-included-fields="tableUserAccounts.filterOn"
      >
        <template #cell(index)="row">
          {{ tableUserAccounts.currentPage * tableUserAccounts.perPage - tableUserAccounts.perPage + (row.index + 1) }}
        </template>

        <template #cell(active)="row">
          {{ row.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell(email)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell()="row">
          <div class="text-nowrap">
            {{ row.value }}
          </div>
        </template>

        <template #cell(action)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowEditFormUserAccount(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableUserAccounts.perPage"
              :options="tableUserAccounts.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableUserAccounts.currentPage"
            :total-rows="tableUserAccounts.totalRows"
            :per-page="tableUserAccounts.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-user-account"
      scrollable
      no-close-on-backdrop
      :title="modalUserAccountTitle"
      @ok="onValidateFormUserAccount"
    >
      <ValidationObserver
        ref="formUserAccount"
      >
        <form
          novalidate
          @submit.prevent
        >
          <b-row>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="email"
                vid="email"
                rules="required|email|max:100"
              >
                <b-form-group>
                  <label for="email">
                    <strong>
                      Email
                    </strong>
                  </label>
                  <b-input
                    id="email"
                    v-model="user.email"
                    type="email"
                    autocomplete="off"
                    placeholder="enter email"
                    :state="errors.length > 0 ? false : null"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="password"
                vid="password"
                :rules="passwordRule"
              >
                <b-form-group>
                  <label for="password">
                    <strong>
                      Password
                    </strong>
                    <span v-if="state.editing">( <i>Leave blank when not changing password</i> )</span>
                  </label>
                  <b-input
                    id="password"
                    v-model="user.password"
                    type="password"
                    autocomplete="off"
                    placeholder="enter password"
                    :state="errors.length > 0 ? false : null"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="password confirmation"
                vid="password_confirmation"
                :rules="passwordConfirmationRule"
              >
                <b-form-group>
                  <label for="password_confirmation">
                    <strong>
                      Password Confirmation
                    </strong>
                    <span v-if="state.editing">( <i>Leave blank when not changing password</i> )</span>
                  </label>
                  <b-input
                    id="password_confirmation"
                    v-model="user.password_confirmation"
                    type="password"
                    autocomplete="off"
                    placeholder="enter password confirmation"
                    :state="errors.length > 0 ? false : null"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

          </b-row>

          <b-row>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="first name"
                vid="first_name"
                rules="required|max:50"
              >
                <b-form-group>
                  <label for="first_name">
                    <strong>
                      First Name
                    </strong>
                  </label>
                  <b-input
                    id="first_name"
                    v-model="user.first_name"
                    type="text"
                    autocomplete="off"
                    placeholder="enter first name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="last name"
                vid="last_name"
                rules="required|max:50"
              >
                <b-form-group>
                  <label for="last_name">
                    <strong>
                      Last Name
                    </strong>
                  </label>
                  <b-input
                    id="last_name"
                    v-model="user.last_name"
                    type="text"
                    autocomplete="off"
                    placeholder="enter last name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <b-form-checkbox
                      id="login_local"
                      v-model="user.login_local"
                      name="login_local"
                      value="1"
                      unchecked-value="0"
                      switch
                      :disabled="state.busy"
                    >
                      Use Login Local?
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <b-form-checkbox
                      id="login_sso"
                      v-model="user.login_sso"
                      name="login_sso"
                      value="1"
                      unchecked-value="0"
                      switch
                      :disabled="state.busy"
                    >
                      Use Login SSO?
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              v-if="state.editing && user.id !== 1"
              cols="12"
            >
              <b-form-group>
                <b-form-checkbox
                  id="is_active"
                  v-model="user.active"
                  name="is_active"
                  value="1"
                  unchecked-value="0"
                  :disabled="state.busy"
                  switch
                >
                  Is Active?
                </b-form-checkbox>
              </b-form-group>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, AdminUserService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminUserAccounts',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'User Accounts'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        editing: false,
        busy: false
      },
      user: {
        id: null,
        email: null,
        first_name: null,
        last_name: null,
        password: null,
        login_local: 1,
        login_sso: 0,
        active: 0
      },
      tableUserAccounts: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'index', class: 'text-center' },
          { key: 'action', class: 'text-center' },
          { key: 'email' },
          { key: 'first_name' },
          { key: 'last_name', sortable: true },
          { key: 'active', class: 'text-center' },
          { key: 'updated_at', formatter: this.dateTimeShortFormatter }
        ]
      }
    }
  },

  computed: {
    modalUserAccountTitle () {
      return this.state.editing ? 'Edit User' : 'Add User'
    },

    passwordRule () {
      return `${this.state.editing ? '' : 'required|'}min:8|confirmed:password_confirmation`
    },

    passwordConfirmationRule () {
      return `${this.state.editing ? '' : 'required'}`
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async myProvider (ctx) {
      return await AdminUserService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter
        })
      ).then(({ data }) => {
        this.tableUserAccounts.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    onShowAddFormUserAccount () {
      this.state.editing = false

      this.user.id = 0
      this.user.email = null
      this.user.first_name = null
      this.user.last_name = null
      this.user.active = 1
      this.user.login_local = 1
      this.user.login_sso = 0
      this.user.password = null
      this.user.password_confirmation = null

      this.$bvModal.show('modal-user-account')
    },

    onShowEditFormUserAccount (user) {
      this.state.editing = true

      this.user.id = user.id
      this.user.email = user.email
      this.user.first_name = user.first_name
      this.user.last_name = user.last_name
      this.user.active = user.active
      this.user.login_local = user.login_local
      this.user.login_sso = user.login_sso

      this.user.password = null
      this.user.password_confirmation = null

      this.$bvModal.show('modal-user-account')
    },

    async onValidateFormUserAccount (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formUserAccount.validate().then(async success => {
        if (success) {
          let text = 'Do you really want to create user?'

          if (this.state.editing) {
            text = 'Do you really want to save changes?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: text,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true
              if (this.state.editing) {
                return this.onPutFormUserAccount()
              } else {
                return this.onPostFormUserAccount()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problems with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onPostFormUserAccount () {
      return new Promise(resolve => {
        AdminUserService.post(this.user).then(({ data }) => {
          this.$bvModal.hide('modal-user-account')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.tableUserAccounts.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formUserAccount.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onPutFormUserAccount () {
      return new Promise(resolve => {
        AdminUserService.put(this.user).then(({ data }) => {
          this.$bvModal.hide('modal-user-account')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.tableUserAccounts.localItems, { id: data.user.id })
            row.email = data.user.email
            row.first_name = data.user.first_name
            row.last_name = data.user.last_name
            row.active = data.user.active
            row.login_local = data.user.login_local
            row.login_sso = data.user.login_sso
            row.updated_at = data.user.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formUserAccount.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    }
  }
}
</script>
